// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  googleTagManager: {
    id: 'GTM-T7LFCDH'
  },
  uaAdminUrl: 'https://admin-stage.urbanairparks.com',
  parkApiUrl: 'https://parksapi-stage.urbanairparks.com',
  productApiUrl: 'https://productapi-stage.urbanairparks.com',
  invoiceApiUrl: 'https://invoiceapi-stage.urbanairparks.com',
  accountApiUrl: 'https://accountsapi-stage.urbanairparks.com',
  mediaApiUrl: 'https://mediaapi-stage.urbanairparks.com',
  bookingApiUrl: 'https://bookingapi-stage.urbanairparks.com',
  unleashedApiUrl: 'https://unleashedapi-stage.urbanairparks.com',
  salesForceUrl: 'https://unleashedbrands--fullub.sandbox.my.salesforce.com',
  salesForceUrlJs: 'https://unleashedbrands--fullub.sandbox.my.salesforce.com/embeddedservice/5.0/esw.min.js',
  salesForceLiveAgentSetupFlowUrl: 'https://unleashedbrands--fullub.sandbox.my.salesforce-sites.com/liveAgentSetupFlow',
  salesForceBaseLiveAgentContentUrl: 'https://c.la4-c1cs-ia4.salesforceliveagent.com/content',
  salesForceBaseLiveAgentUrl: 'https://d.la4-c1cs-ia4.salesforceliveagent.com/chat',
  salesForceKey: '00D530000004f6Z',
  uaPhoneNumber: '800-867-5309',
  cookieName: 'stg-ua-booking-cart',
  bookingCookieName: 'stg-ua-booking',
  enableGtm: true,
  auth: {
    issuer: 'https://securityapi-stage.urbanairparks.com',
    clientId: 'urban-air',
    clientSecret: 'hvUxcVhby0M35cB69si9',
    scopes: [
      'accounts',
      'accounts:create',
      'accounts:read',
      'accounts:update',
      'account-waivers:create',
      'account-waivers:read',
      'account-waivers:update',
      'invoices',
      'invoices:create',
      'invoices:preview',
      'openid',
      'payments',
      'profile',
      'offline_access'
    ],
    cookies: {
      domain: '.urbanairparks.com',
      duration: 86400000,
      namespace: 'ua_stage',
      secure: false
    }
  }
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
